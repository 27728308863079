@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: '';
}

h1, h2, h3, h4, h5, h6,p{
  font-family: 'Verdana' ;
}

*{
    font-family: "Montserrat", sans-serif;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; 
    border-radius: 10px; */
    background-color: #0a192f;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #013f3c7f; 
    border-radius: 10px;
  }

  i{
    cursor:pointer;
    transition: color 300ms, transform 300ms;
  }
  i:hover{
    color: #38B2AC;
    transform: scale(1.1);    
  }

  input,textarea{
    height: 40px;
    border: 1px solid gray;
    width: 100%;
    padding-left: 20px;
  }

  input:focus{
    outline: none;
    border: 2px solid rgb(68, 67, 67);
  }

  textarea{
    height: 100px;
    padding-top: 10px;
  } 


  /* // Loading CSS */

  .loader {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 180px;
}

.circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #3498db;
    animation: bounce 1.2s infinite ease-in-out;
}

.circle:nth-child(2) {
    background-color: #e74c3c;
    animation-delay: 0.2s;
}

.circle:nth-child(3) {
    background-color: #f39c12;
    animation-delay: 0.4s;
}

.circle:nth-child(4) {
    background-color: #2ecc71;
    animation-delay: 0.6s;
}


@keyframes bounce {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}


/* // fixed navbar */

/* CSS for navbar */
.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure it's above other content */  
}

/* CSS for sub-menu of navbar */
.ant-menu-dark{
  background-color: #171616 !important;
}

/* antd menu css */
.ant-menu-title-content{    
  color: snow;
  font-weight: bold;
}

.ant-menu-dark.ant-menu-submenu-popup>.ant-menu
{
  background-color: #171616 !important;
}
.ant-menu-dark.ant-menu-horizontal >.ant-menu-item-selected
{
  background-color: #171616 !important;  
  border-bottom: 1px solid #38B2AC;
  border-radius: 7px;
}

#menu ul li{  
  color: snow;
  font-weight: bold;
}


/* Our services section */

.our-services{
  border-width: 2px; /* Equivalent to border-2 */
  border-color: #6b7280; /* Equivalent to border-neutral-500 (adjust the color code as per your Tailwind configuration) */
  box-shadow: 0 20px 25px -5px rgba(0, 183, 202, 0.50), 0 10px 10px -5px rgba(0, 183, 202, 0.40); /* Equivalent to shadow-cyan-500/50 and shadow-xl */
  padding: 18% 10% 18% 10%; 
  border-radius: 0.5rem; /* Equivalent to rounded-lg (0.5rem or 8px, adjust if needed) */  
}

